import logo from './logo.svg';
import './App.css';
import { RedocStandalone } from 'redoc';
function App() {
  return (
    <>
    <RedocStandalone
  specUrl="assets/openapi.yaml"

  options={{
      nativeScrollbars: true,
      theme: { colors: { primary: { main: '#dd5522' } }, fab: { backgroundColor: '#dd5522', color: 'white'} },
      hideDownloadButton: true,
      menuToggle: true,
    }}
/>
    </>
  );
}

export default App;
